new Vue({
	el: "#tags",
	created: function() {
		this.getTags();
	},
	data: {
		tags: []
	},
	methods: {
		getTags: function() {
			var url = '/tags.json';
			axios.get(url).then(resoponse => {
				this.tags = resoponse.data.tags
			});
		}
	}
});