new Vue({
	el: "#myTabContent",
	created: function() {
		this.getPopulars();
		this.getLastest();
	},
	data: {
		populars: [],
		lastest: []
	},
	methods: {
		getPopulars: function() {
			var url = '/posts/populars.json';
			axios.get(url).then(resoponse => {
				this.populars = resoponse.data.posts
			});
		},
		getLastest: function() {
			var url = '/posts/lastest.json';
			axios.get(url).then(resoponse => {
				this.lastest = resoponse.data.posts
			});
		}
	}
});

new Vue({
	el: "#footer-populars",
	created: function() {
		this.getPopulars();
	},
	data: {
		footerPopulars: []
	},
	methods: {
		getPopulars: function() {
			var url = '/posts/populars.json';
			axios.get(url).then(resoponse => {
				this.footerPopulars = resoponse.data.posts
			});
		}
	}
});